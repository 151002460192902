import Vue from "vue";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./utils/scss/element_reset.scss";
import "./utils/scss/mixins.scss"
import request from "../src/utils/request";

import VueLazyLoad from 'vue-lazyload' //图片优化懒加载
Vue.use(VueLazyLoad, {
    preLoad: 1,
    // error: require('./assets/img/error.jpg'),
    // loading: 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-f9d98a60-ed01-49ed-b2cc-7838bea6655a/6e87d238-be61-400b-9d55-15efb7cef6d7.gif',
    attempt: 2,
})


Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$export = request.export;
Vue.prototype.$download = request.download;
Vue.prototype.$upload = request.upload;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");