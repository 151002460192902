<!-- 脚注 -->
<template>
    <div>
        <div class="content">
            <div class="box">
                <div class="logo">
                    <img class="logo-img" src="../../assets/foot/logo-foot.png" />
                </div>
                <div class="list">
                    <div class="list-item" v-for="(item, index) in list" :key="index">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="children" v-for="(value, vId) in item.explain" :key="vId">
                            <div v-if="value.icon">
                                <img class="list-icon" :src="value.icon" />
                            </div>
                            <router-link :to="value.path" class="router-link">{{ value.value }}</router-link>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="QRcode">
                            <img class="img" src="../../assets/qr-code/wx.png" alt="" />
                            <div>官方微信</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-foot">
					隐私条款 服务条款 © 2013-2023 平正云科技有限公司. All Rights Reserved.<br/>
					沪备案/许可证编号：<a href="http://www.beian.miit.gov.cn/" target="_blank">沪ICP备16049737号-1</a>  <img src="../../assets/foot/icon_01.png"> 公安备案号：<a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002003595" target="_blank">31011002003595</a></p>
				</div>
            </div>
        </div>
        <div class="mobile">
            <div class="mobile-box">
                <div class="list" v-for="(item, index) in list" :key="index">
                    <div class="list-title" @click="showList(index)">
                        <div>{{ item.title }}</div>
                        <img
                            class="unfold"
                            src="../../assets/foot/unfold.png"
                            alt=""
                            :class="index == showIndex ? 'fold' : 'unfold'"
                        />
                    </div>
                    <template v-if="index == showIndex">
                        <div class="list-child" v-for="(val, vId) in item.explain" :key="vId">
                            <router-link :to="val.path" class="router-link">{{ val.value }}</router-link>
                        </div>
                    </template>
                </div>
            </div>
            <div class="copy-right">
                <p>隐私条款 服务条款</p>
                <p>© 2013-2023 平正云科技有限公司. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            list: [
                {
                    title: "产品",
                    explain: [
                        {
                            value: "下载安装",
                            path: "/download",
                        },
                        {
                            value: "产品报价",
                            path: "",
                        },
                        {
                            value: "应用商店",
                            path: "",
                        },
                    ],
                },
                {
                    title: "资源",
                    explain: [
                        {
                            value: "帮助中心",
                            path: "",
                        },
                        {
                            value: "技术服务/支持",
                            path: "",
                        },
                        {
                            value: "常见问题",
                            path: "/product#listProblem",
                        },
                        {
                            value: "更新日志",
                            path: "",
                        },
                    ],
                },
                {
                    title: "公司",
                    explain: [
                        {
                            value: "关于我们",
                            path: "/aboutUs",
                        },
                        {
                            value: "加入我们",
                            path: "/joinUs",
                        },
                        {
                            value: "联系我们",
                            path: "/contactUs",
                        },
                        {
                            value: "服务合作",
                            path: "",
                        },
                    ],
                },
                {
                    title: "联系我们",
                    explain: [
                        {
                            value: "139 1654 0399",
                            path: "",
                            icon: require("../../assets/foot/tel.png"),
                        },
                        {
                            value: "服务时间： 周一至周五 09:00-18:00",
                            path: "",
                            icon: require("../../assets/foot/time.png"),
                        },
                        {
                            value: "邮箱: liujunyang@pingzhengyun.com",
                            path: "",
                            icon: require("../../assets/foot/email.png"),
                        },
                        {
                            value: "地址: 上海市杨浦区纪念路8号财大科技园5号楼110室",
                            path: "",
                            icon: require("../../assets/foot/location.png"),
                        },
                    ],
                },
            ],
            showIndex: -1, //当前展开的页脚子选项
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            if (to) {
                this.showIndex = -1;
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * 点击展示页脚子选项
         */
        showList(e) {
            this.showIndex = this.showIndex == e ? -1 : e;
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    .mobile {
        display: none;
    }
    .content {
        width: 100%;
        min-height: 25rem;
        background-color: #031722;
        padding: 4rem 0;
        position: relative;
        background-image: url("../../assets/foot/watermark.png");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 16.2rem 20rem;
    }
    .box {
        width: 67.5rem;
        margin: auto;
        .logo {
            width: 100%;
            margin-bottom: 3.8rem;
            .logo-img {
                width: 8.5rem;
                height: 2.6rem;
            }
        }
        .list {
            display: flex;
            justify-content: space-around;
            color: rgba(#ffffff, 0.5);
            font-size: 0.8rem;
            .list-item {
                min-width: 13%;
                margin: 0;
                padding: 0;
                .title {
                    color: #ffffff;
                    font-size: 1rem;
                    line-height: 1rem;
                    margin-bottom: 1.35rem;
                }
                .children {
                    margin-bottom: 0.85rem;
                    display: flex;
                    align-items: center;
                    .list-icon {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-right: 1rem;
                    }
                }
                .children:hover a {
                    cursor: pointer;
                    color: #ffffff;
                }
                .QRcode {
                    margin: auto;
                    text-align: center;
                    .img {
                        width: 6.7rem;
                        height: 6.7rem;
                        margin-top: 3rem;
                        margin-bottom: 0.9rem;
                    }
                }
            }
        }
    }
    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        color: rgba(#ffffff, 0.5);
        font-size: 0.7rem;
        .explain-link {
            width: 67.5rem;
            margin: 1.3rem auto;
        }
        .bottom-foot {
            height: 3rem;
            width: 100%;
            border-top: 1px solid rgba(#ffffff, 0.07);
            text-align: center;
            line-height: 24px;
			padding: 1rem 0;
			a{
				color: rgba(#ffffff, 0.5);
				text-decoration:none;
			}
			a:hover{
				text-decoration:underline;
			}
        }
    }
}
@media screen and (max-width: 750px) {
    .content {
        display: none;
    }
    .mobile {
        background: url("../../assets/foot/watermark.png") no-repeat left bottom/16.2rem 20rem;
        width: 100%;
        background-color: #031722;
        padding: 1rem 0;
        box-sizing: border-box;
        color: #ffffff;
        .mobile-box {
            padding: 0 3rem;
            box-sizing: border-box;
            .list {
                width: 100%;
                border-bottom: 1px solid rgba(#ececf2, 0.25);
                padding: 0 0.5rem;
                transition: all 0.5s ease;
                .list-title {
                    height: 5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 5rem;
                    font-size: 1.6rem;
                }
                .unfold {
                    width: 1.2rem;
                    height: 1.2rem;
                    transition: 0.4s ease;
                }
                .fold {
                    transform: rotate(-45deg);
                }
                .list-child {
                    color: rgba(#ffffff, 0.6);
                    font-size: 1.4rem;
                    margin-bottom: 1.8rem;
                }
            }
        }
        .explain-link {
            font-size: 1.3rem;
            color: rgba(#ffffff, 0.5);
            padding: 0 3rem;
            box-sizing: border-box;
            margin: 2.5rem 0;
            line-height: 2.1rem;
        }
        .copy-right {
            border-top: 1px solid rgba(#ffffff, 0.07);
            padding: 1.25rem 0 2.2rem;
            box-sizing: border-box;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 1.7rem;
            text-align: center;
            color: rgba(#ffffff, 0.3);
        }
    }
}
.router-link {
    text-decoration: none;
    color: rgba(#ffffff, 0.5);
}
</style>
