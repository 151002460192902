import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import db from "../utils/localstorage";
import pageView from "../views/layout/PageView.vue";
// import index from "../views/index/index.vue"; //首页
// import product from "../views/product/product.vue";
// import product2 from "../views/product/product2.vue";
// import product3 from "../views/product/product3.vue";
// import solution from "../views/solution/solution.vue";
// import solution2 from "../views/solution/solution2.vue";
// import solution3 from "../views/solution/solution3.vue";
// import solution4 from "../views/solution/solution4.vue";
// import customerCase from "../views/customerCase/customerCase.vue";
// import caseDetails from "../views/customerCase/caseDetails.vue";
// import joinUs from "@/views/joinUs/joinUs";
// import aboutUs from "@/views/aboutUs/aboutUs";
// import contactUs from "@/views/contactUs/contactUs";
// import download from "@/views/download/download";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/",
    component: pageView,
    children: [{
            path: "/",
            name: "index",
            component: () => import('../views/index/index.vue'),
            meta: {
                title: "平正云科技",
            },
        },
        {
            path: "/product",
            name: "product",
            component: () => import('../views/product/product.vue'),
            meta: {
                title: "智慧作业平台",
            },
        },
        {
            path: "/product2",
            name: "product2",
            component: () => import('../views/product/product2.vue'),
            meta: {
                title: "协同治理平台",
            },
        },
        {
            path: "/product3",
            name: "product3",
            component: () => import('../views/product/product3.vue'),
            meta: {
                title: "数字化内推产品",
            },
        },
        {
            path: "/solution",
            name: "solution",
            component: () => import('../views/solution/solution.vue'),
            meta: {
                title: "智慧园区解决方案",
            },
        },
        {
            path: "/solution2",
            name: "solution2",
            component: () => import('../views/solution/solution2.vue'),
            meta: {
                title: "可视化解决方案",
            },
        },
        {
            path: "/solution3",
            name: "solution3",
            component: () => import('../views/solution/solution3.vue'),
            meta: {
                title: "环卫一体化解决方案",
            },
        },
        {
            path: "/solution4",
            name: "solution4",
            component: () => import('../views/solution/solution.vue'),
            meta: {
                title: "智慧景区解决方案",
            },
        },
        {
            path: "/customerCase",
            name: "customerCase",
            component: () => import('../views/customerCase/customerCase.vue'),
            meta: {
                title: "客户案例",
            },
        },
        {
            path: "/customerCase/caseDetails/:index",
            name: "caseDetails",
            component: () => import('../views/customerCase/caseDetails.vue'),
            meta: {
                title: "案例详情",
            },
        },
        {
            path: "/joinUs",
            name: "joinUs",
            component: () => import('@/views/joinUs/joinUs'),
            meta: {
                title: "加入我们",
            },
        },
        {
            path: "/aboutUs",
            name: "aboutUs",
            component: () => import('@/views/aboutUs/aboutUs'),
            meta: {
                title: "关于我们",
            },
        },
        {
            path: "/contactUs",
            name: "contactUs",
            component: () => import('@/views/contactUs/contactUs'),
            meta: {
                title: "联系我们",
            },
        },
        {
            path: "/download",
            name: "download",
            component: () => import('@/views/download/download'),
            meta: {
                title: "产品下载",
            },
        },
    ],
}, ];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    // base: "/index/",
    base: "/", //上线路径配置
    routes,
});

let menu = store.state.navList;
let active;
// 以下的代码主要针对tab栏的选中状态
router.beforeEach((to, from, next) => {

    active = to.fullPath;
    menu.forEach((item) => {
        item.isActive = false;
    });
    if (active === "/") {
        menu[0].isActive = true;
    } else {
        // 判断路由是否是页面内锚链接#跳转
        active = active.indexOf("#") != -1 ? active.split("#")[0] : active;
        menu.forEach((item, index) => {
            if (active == item.path) {
                item.isActive = true;
            } else {
                if (item.hasChildren) {
                    item.children.forEach((item2, index2) => {
                        if (active == item2.path) {
                            item.isActive = true;
                        }
                    });
                }
            }
        });
    }

    store.commit("setNavList", menu);
    // 浏览器title
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
export default router;