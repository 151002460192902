<!-- 分享 二维码电话等  及返回顶部 -->
<template>
    <!-- 浮窗 pc -->
    <section>
        <div class="float-window" v-if="shareObj.isMobile && shareObj.share">
            <div v-for="(item, index) in floatList" :key="index" class="info-box">
                <template v-if="item.isActive">
                    <div class="info" v-if="item.details">
                        <h2>{{ item.info }}</h2>
                        <img v-if="item.img" :src="item.img" alt="" class="code" />
                        <h4>{{ item.h4 }}</h4>
                        <p>{{ item.details }}</p>
                    </div>
                </template>
            </div>
            <div class="float">
                <ul>
                    <li v-for="(item, index) in floatList" :key="index" @click="floatClick(index)">
                        <img v-if="!item.isActive" :src="item.icon" alt="" />
                        <img v-if="item.isActive" :src="item.iconT" alt="" />
                        <p :class="item.isActive ? 'isActive' : ''">{{ item.title }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="float-window-mobile" v-else>
            <a href="tel:13916540399">
                <img src="../../assets/float-window/tel-w@3x.png" alt="" />
                电话咨询
            </a>
            <div class="line"></div>
            <p @click="showQrCode">
                <img src="../../assets/float-window/wechat-w@3x.png" alt="" />
                在线咨询
            </p>
        </div>
        <!-- 移动端二维码 在线咨询-->
        <contactUs ref="contactUs"></contactUs>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixins } from "../../utils/mixin/mixin";
import contactUs from "@/components/contactUs/contactUs";
export default {
    mixins: [myMixins],
    components: { contactUs },
    data() {
        return {
            floatList: [
                {
                    title: "电话",
                    isActive: false,
                    icon: require("../../assets/float-window/icon1.png"),
                    iconT: require("../../assets/float-window/iconT1.png"),
                    hasChildren: true,
                    info: "139 1654 0399",
                    details: "联系专属客户经理为您解答",
                },
                {
                    title: "微信",
                    isActive: false,
                    icon: require("../../assets/float-window/icon2.png"),
                    iconT: require("../../assets/float-window/iconT2.png"),
                    hasChildren: true,
                    img: require("../../assets/qr-code/wx.png"),
                    h4: "微信扫一扫",
                    details: "专属客户经理在线为您解答",
                },
                {
                    title: "TOP",
                    isActive: false,
                    icon: require("../../assets/float-window/icon3.png"),
                    iconT: require("../../assets/float-window/iconT3.png"),
                },
            ], //浮窗
            shareObj: {
                isMobile: true, //小于750不显示
                share: false,
            }, //分享控制
        };
    },
    computed: {
        ...mapGetters(["getScrollTop", "getClientWidth"]),
    },
    watch: {},
    created() {},
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        if (this.getClientWidth <= 750) {
            this.shareObj.isMobile = false;
        }
    },
    methods: {
        // 浮窗事件
        floatClick(e) {
            this.floatList.map((item, index) => {
                item.isActive = e === index ? !item.isActive : false;
                if (e === 2) {
                    item.isActive = false;
                    scrollTo(0, 0);
                }
            });
        },
        handleScroll() {
            // 页面滑动的距离
            let scrollTop = document.documentElement.scrollTop;
            // 当页面滑动的距离大于?时元素显示，否则不改变
            this.shareObj.share = scrollTop >= 1080 ? true : false;
        },
    },
    // 组件销毁前
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped lang="scss">
@mixin flex($justify-content: space-between, $align-items: center, $flex-direction: row) {
    display: flex;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    box-sizing: border-box;
}
@media screen and (min-width: 751px) {
    .float-window {
        @include flex(center, flex-start);
        position: fixed;
        right: 50px;
        // top: 30rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
        .info-box {
            @include flex(flex-start, flex-start, column);

            .info {
                background-color: #fff;
                position: relative;
                margin-right: 10px;
                border: 1px solid #d6e1ee;
                box-shadow: 0px 10px 16px 0px rgba(3, 23, 34, 0.04);
                border-radius: 5px;
                @include flex(center, center, column);

                &:before {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-color: transparent transparent transparent #fff;
                    border-width: 10px 0px 10px 10px;
                    position: absolute;
                    right: -10px;
                    transform: translateY(-50%);
                    top: 50%;
                }

                h2 {
                    font-size: 1.2rem;
                    color: #031722;
                    line-height: 1.2rem;
                    font-weight: 600;
                }

                img {
                    width: 8rem;
                    height: 8rem;
                }

                h4 {
                    font-size: 1rem;
                    color: #031722;
                    text-align: center;
                    line-height: 1rem;
                    font-weight: 500;
                    margin-top: 0.75rem;
                }

                p {
                    margin-top: 0.5rem;
                    font-size: 0.7rem;
                    color: #999999;
                    text-align: center;
                    line-height: 0.7rem;
                    font-weight: 400;
                }
            }

            &:nth-child(1) {
                .info {
                    &:nth-child(1) {
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding: 1rem 5.05rem 1rem 1rem;

                        p {
                            margin-top: 0.25rem;
                        }
                    }
                }
            }

            &:nth-child(2) {
                align-self: center;

                .info {
                    min-width: 14.35rem;
                    padding: 0.8rem 3rem;
                    box-sizing: border-box;
                    max-width: 8.4rem;

                    .code {
                        width: 8rem;
                        height: 8rem;
                    }

                    p {
                        margin-top: 0.5rem;
                        font-size: 0.7rem;
                        color: #999999;
                        text-align: center;
                        line-height: 1.2rem;
                        font-weight: 400;
                        word-break: keep-all;
                    }
                }
            }
        }

        .float {
            width: 4rem;

            ul {
                background: #ffffff;
                border: 1px solid #d6e1ee;
                box-shadow: 0px 10px 16px 0px rgba(3, 23, 34, 0.04);
                border-radius: 5px;
                box-sizing: border-box;

                li {
                    @include flex(center, center, column);
                    height: 4.4rem;
                    border-bottom: 1px solid #f2f6f9;
                    box-sizing: border-box;
                    cursor: pointer;

                    &:nth-last-child(1) {
                        border-top: none;
                    }

                    img {
                        width: 1.8rem;
                        height: 1.8rem;
                    }

                    p {
                        margin-top: 4px;
                        font-size: 0.9rem;
                        color: #666666;
                        text-align: center;
                        line-height: 0.9rem;
                        font-weight: 400;
                    }

                    .isActive {
                        color: #1976e1;
                    }
                }
            }
        }
    }
    .float-window-mobile {
        display: none;
    }
}
@media screen and (max-width: 750px) {
    .float-window {
        display: none;
    }

    .float-window-mobile {
        position: fixed;
        bottom: 0;
        height: 5rem;
        width: 100%;
        background-image: linear-gradient(135deg, #3995ff 0%, #1976e1 100%);
        box-shadow: 0px -5px 33px 0px rgba(6, 24, 45, 0.14);
        @include flex();

        a {
            flex: 1;
            @include flex(center);
            font-size: 1.5rem;
            color: #ffffff;
            font-weight: 400;
            text-decoration: none;
        }
        .line {
            height: 2.5rem;
            width: 1px;
            background-color: rgba(#fff, 0.2);
        }
        p {
            flex: 1;
            @include flex(center);
            font-size: 1.5rem;
            color: #ffffff;
            font-weight: 400;
        }
        img {
            height: 2.4rem;
            width: 2.4rem;
            margin-right: 0.75rem;
        }
    }
}
</style>
