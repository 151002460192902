<!--  -->
<template>
    <div id="content">
        <slot></slot>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss">
#content {
    z-index: 99 !important;
}
</style>
