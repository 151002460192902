<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import db from "../src/utils/localstorage";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data() {
        return {};
    },
    computed: {},
    created() {
        let screenWidth = window.document.body.clientWidth || document.documentElement.clientWidth;
        this.$store.commit("setClientWidth", screenWidth);
    },

    methods: {
        ...mapMutations(["setScrollTop", "setClientWidth"]),
    },
    destroyed() {
        db.remove("navList");
        db.clear();
    },
};
</script>
<style lang="scss">
*,
body,
html {
    margin: 0;
    padding: 0;
    // scroll-behavior: smooth;
    // transition: 0.3s ease;
}
// ul清除样式
ul {
    list-style-type: none;
}

span {
    a {
        text-decoration: none;
        color: #fff;
        display: inline-block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

@import "common/font/font.css";
</style>
