import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        scrollTop: Number,
        clientWidth: Number,
        navList: [{
                name: "首页",
                isActive: true,
                path: "/"
            },
            {
                name: "产品",
                isActive: false,
                path: "/product",
                hasChildren: true,
                children: [{
                        name: "智慧作业平台",
                        path: "/product"
                    },
                    {
                        name: "协同治理平台",
                        path: "/product2"
                    },
                    {
                        name: "数字化内推平台",
                        path: "/product3"
                    },
                ],
            },
            {
                name: "解决方案",
                isActive: false,
                path: "/solution",
                hasChildren: true,
                children: [{
                        name: "智慧园区解决方案",
                        path: "/solution"
                    },
                    {
                        name: "可视化解决方案",
                        path: "/solution2"
                    },
                    {
                        name: "环卫一体化解决方案",
                        path: "/solution3"
                    },
                    {
                        name: "智慧景区解决方案",
                        path: "/solution4"
                    },
                ],
            },
            {
                name: "客户案例",
                isActive: false,
                path: "/customerCase",
            },
            {
                name: "公司介绍",
                isActive: false,
                path: "/aboutUs",
                hasChildren: true,
                children: [{
                        name: "关于我们",
                        path: "/aboutUs"
                    },
                    {
                        name: "联系我们",
                        path: "/contactUs"
                    }
                ],
            },
            {
                name: "加入我们",
                isActive: false,
                path: "/joinUs",
            },
        ], //nav内容
    },
    mutations: {
        setScrollTop(state, val) {
            state.scrollTop = val;
        },
        setClientWidth(state, val) {
            state.clientWidth = val;
        },
        setNavList(state, val) {
            state.navList = val;
        },
    },
    getters: {
        getScrollTop(state) {
            return state.scrollTop;
        },
        getClientWidth(state) {
            return state.clientWidth;
        },
    },
    actions: {},
    modules: {},
});