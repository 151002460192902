<!--  -->
<template>
    <div class="">
        <GlobalHeader></GlobalHeader>
        <!-- <GlobalLayout></GlobalLayout> -->
        <!-- <keep-alive> -->
        <PageContent>
            <slot></slot>
        </PageContent>
        <!-- </keep-alive> -->
        <GlobalFooter></GlobalFooter>
    </div>
</template>

<script>
import GlobalHeader from "@/views/layout/GlobalHeader";
import PageContent from "@/views/layout/PageContent";
import GlobalFooter from "@/views/layout/GlobalFooter";
import GlobalLayout from "@/views/layout/GlobalLayout";
export default {
    components: { GlobalHeader, PageContent, GlobalFooter, GlobalLayout },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss"></style>
