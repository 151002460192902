<template>
    <div class="nav" :class="navScroll ? 'bg-color' : 'nav'">
        <img src="../../assets/navigator/logoPzy.png" alt="" />
        <div class="nav-a">
            <ul class="nav-a-ul">
                <li
                    class="nav-a-li"
                    :class="classA == index ? 'active' : ''"
                    @click="selected(index)"
                    v-for="(item, index) in configNav"
                    :key="index"
                >
                    <router-link :to="item.path" class="nav-a-text" @click="showToggle(index)">
                        {{ item.name }}
                    </router-link>
                    <ul class="menu_ul" :class="{ active: index === isShow }" v-if="item.subItems">
                        <li
                            class="menu_li"
                            v-for="(nav, index1) in item.subItems"
                            :class="classB == index1 ? 'active' : ''"
                            @click="menuselected(nav)"
                            :key="index1"
                        >
                            <router-link
                                class="menu_ul_text"
                                :to="nav.link"
                                :class="{ active: nav.link == linkClick }"
                                @click="treeNavSwitch(nav)"
                                >{{ nav.text }}</router-link
                            >
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            show: false,
            classA: 0,
            classB: 0,
            isShow: 0,
            linkClick: "",
            configNav: [
                {
                    name: "首页",
                    path: "/",
                },
                {
                    name: "产品",
                    path: "/product",
                },
                {
                    name: "解决方案",
                    path: "/solution",
                    subItems: [
                        { link: "/solution", text: "智慧园区解决方案" },
                        { link: "/product", text: "营销招生系统" },
                    ],
                },
                {
                    name: "客户案例",
                    path: "/product",
                },
                {
                    name: "公服智库/财大",
                    path: "/product",
                },

                {
                    name: "关于我们",
                    path: "",
                },
            ],
            navScroll: false, //滚动距离改变底色
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    // 组件销毁前
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        db.remove("navList");
    },
    methods: {
        selected: function(index) {
            this.classA = index;
        },
        menuselected: function(nav) {
            this.classB = nav;
        },
        showToggle: function(index) {
            this.isShow = index;
        },
        treeNavSwitch: function(nav) {
            this.linkClick = nav.link;
        },
        // 监听屏幕变化改变显示
        handleScroll() {
            // 页面滑动的距离
            let scrollTop = document.documentElement.scrollTop;
            // 当页面滑动的距离大于?时元素显示，否则不显示
            if (scrollTop >= 800) {
                this.navScroll = true;
            } else {
                this.navScroll = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.nav {
    width: 100%;
    padding: 0 13rem;
    box-sizing: border-box;
    height: auto;
    margin: 0px auto;
    position: fixed;
    border-bottom: none;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        width: 8.75rem;
        height: 2.7rem;
    }
}
.bg-color {
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
    ul {
        li {
            color: #031722 !important;
            &:hover {
                color: #1976e1;
                cursor: pointer;
            }
        }
        .isActive {
            color: #1976e1;
            background: #f5f5fb !important;
            border-bottom: 0.2rem;
            border-bottom-style: solid;
            border-bottom-color: #2897db;
        }
    }
}
.nav-a {
    cursor: pointer;
    position: relative;
}
.nav-a-ul {
    list-style: none;
}
.nav-a-li {
    display: inline-block;
    box-sizing: border-box;
    color: rgba(#eff6ff, 0.5);
    height: inherit;
    line-height: 4.4rem;
    border-bottom: 0.2rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    font-size: 0.9rem;
    border-right: 0.05rem solid rgba(#ffffff, 0.06);
}
.nav-a-text {
    height: 100%;
    width: 100%;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding: 0 2.175rem;
    box-sizing: border-box;
}
.menu_ul {
    // width: 10rem;
    list-style: none;
    background: #fff;
    border-radius: 3px;
    z-index: 999;
    position: absolute;
    top: 100%;
    /* left: 20px; */
    display: none;
    padding: 0.3rem 2rem 2rem 1rem;
    box-sizing: border-box;
}
.menu_li {
    height: 30px;
    line-height: 30px;
}
.menu_ul_text {
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: none;
    // padding-left: 6px;
}
.nav-a-li:hover {
    // border-bottom: 2px solid #fff;
    color: #eff6ff;
    background: rgba(#2897db, 0.2);
}
.nav-a-li.active {
    border-bottom: 0.2rem;
    border-bottom-style: solid;
    border-bottom-color: #2897db;
    color: #eff6ff;
    background: rgba(#2897db, 0.2);
}
/* .nav-a-li:active {
  border-bottom: 2px solid #fff;
} */
.nav-a-li:hover .menu_ul {
    display: block;
}
.menu_ul_text:hover {
    color: #2589ff;
}
.menu_li.active .menu_ul_text {
    color: #2589ff;
}
</style>
