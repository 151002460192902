<!-- 联系我们组件  -->
<template>
    <div class="">
        <div class="qr-code" v-if="isqrCode" @click="closeCode">
            <img src="../../assets/product/qr-code.png" alt="" />
        </div>
        <!-- 移动端二维码 -->
        <div class="qr-code-mobile" v-if="isqrCode">
            <div class="content">
                <img src="../../assets/product/close.png" alt="" class="close" @click="closeCode" />
                <img src="../../assets/product/yes.png" alt="" class="yes" />
                <h2>复制成功</h2>
                <p>微信号: sparklehorse_rip</p>
                <p>添加微信好友，详细了解产品</p>
                <span @click="openWeChat">打开微信</span>
                <img src="../../assets/product/add.png" alt="" class="add" />
            </div>
        </div>
				<!--移动端二维码-->
				<div class="qr-code-box" v-if="showQrCode">
				    <div class="content-box">
				        <img src="../../assets/product/close.png" alt="" class="close" @click="closeQrCode" />
				        <img v-lazy="require('../../assets/qr-code/wx.png')" alt="" class="wx_qrcode" />
				        <p class="title">微信扫一扫</p>
				        <p class="detail">专属客户经理在线为您解答</p>
				    </div>
				</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isqrCode: false, //默认不显示二维码
						showQrCode: false,//是否显示二维码，默认false
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
			closeQrCode() {
			    this.showQrCode = false;
			    this.endScroll();
			},
			closeCode() {
					this.isqrCode = false;
					this.endScroll();
			},
			// 弹窗关闭恢复滚动
			endScroll() {
					let body = document.body;
					body.style.position = "";
					let top = body.style.top;
					document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
					body.style.top = "";
			},
			// 打开微信
			openWeChat() {
					window.location.href = "weixin://";
			},
    },
};
</script>

<style scoped lang="scss">
@mixin flex($justify-content: space-between, $align-items: center, $flex-direction: row) {
    display: flex;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    box-sizing: border-box;
}
@media screen and (min-width: 751px) {
    .qr-code {
        position: fixed;
        height: 100%;
        width: 100%;
        @include flex(center);
        z-index: 999;
        top: 0;

        img {
            width: 25rem;
            height: 25rem;
            cursor: pointer;
        }
    }

    .qr-code-mobile {
        display: none;
    }
}
@media screen and (max-width: 750px) {
    .qr-code {
        display: none;
    }
		
		.qr-code-box{
			position: fixed;
			top: 0;
			min-width: 100vw;
			min-height: 100vh;
			background: rgba(0, 0, 0, 0.6);
			@include flex(center);
			padding: 0 3.8rem;
			z-index: 1000;
			
			.content-box{
				width: 50%;
				padding: 1rem;
				border-radius: 8px;
				background-color: #fff;
				text-align: center;
				
				.wx_qrcode{
					width: 100%;
				}
				
				img {
				    display: block;
				}
				
				.close {
				    width: 1.3rem;
				    height: 1.3rem;
				    margin-left: auto;
				    cursor: pointer;
						margin-bottom: 1rem;
				}
				
				.yes {
				    width: 5rem;
				    height: 5rem;
				    margin: 0 auto;
				}
				.title{
					font-size: 2rem;
					padding: 1rem 0;
					color: #031722;
				}
				.detail{
					font-size: .8rem;
					padding-bottom: .2rem;
					color: #999;
				}
			}
		}

    .qr-code-mobile {
        position: fixed;
        top: 0;
        min-width: 100vw;
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        @include flex(center);
        padding: 0 3.8rem;
        z-index: 1000;

        .content {
            width: 100%;
            padding: 1rem;
            border-radius: 8px;
            background-color: #fff;
            text-align: center;

            img {
                display: block;
            }

            .close {
                width: 1.3rem;
                height: 1.3rem;
                margin-left: auto;
                cursor: pointer;
            }

            .yes {
                width: 5rem;
                height: 5rem;
                margin: 0 auto;
            }

            h2 {
                margin-top: 1rem;
                font-size: 1.8rem;
                color: #031722;
            }

            p {
                font-size: 1.5rem;
                color: #606060;
                margin: 0.5rem 0;
            }

            span {
                display: block;
                width: 13rem;
                height: 3.6rem;
                background-color: #1976e1;
                color: #fff;
                @include flex(center);
                margin: 1.5rem auto;
                font-size: 1.4rem;
            }

            .add {
                width: 25.7rem;
                height: 14.8rem;
                margin: 0 auto;
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
