export const myMixins = {
    data() {
        return {
            listCaseList: [{
              children: [{
								title: "上海财经大学千村调研2.0系统",
								details: "“千村调查”项目是上海财经大学“211工程”三期创新人才培养项目，已成功实施十三期。“走千村，访万户，读中国”。“千村调查”项目是以 …",
								path: "/customerCase/caseDetails/0",
								children: [{
												name: "调研队伍(支)",
												numbers: "1500+"
											},
											{
												name: "调研村庄(个)",
												numbers: "2000+"
											},
											{
												name: "调研学生(人)",
												numbers: "5000+"
											},
										],
									}, {
											title: "保利物业百优行动品检项目",
											details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设，以及场景化管理方面建立更出色的管理能力…",
											path: "/customerCase/caseDetails/1",
											children: [{
															name: "分公司(家)",
															numbers: "26"
													},
													{
															name: "项目(个)",
															numbers: "373"
													},
													{
															name: "检查天数(天)",
															numbers: "100"
													},
											],
									}],
                },
                {
                    children: [{
                            title: "西塘全域化公共服务项目",
                            details: "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导、企业协同、服务对象积极参与”的三位一体全域化服务，打…",
                            path: "/customerCase/caseDetails/2",
                            children: [{
                                    name: "分公司(家)",
                                    numbers: "453"
                                },
                                {
                                    name: "项目(个)",
                                    numbers: "10"
                                },
                                {
                                    name: "检查天数(天)",
                                    numbers: "2w+"
                                },
                            ],
                        },
                        {
                            title: "保利物业百优行动品检项目",
                            details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设，以及场景化管理方面建立更出色的管理能力…",
                            path: "/customerCase/caseDetails/1",
                            children: [{
                                    name: "分公司(家)",
                                    numbers: "26"
                                },
                                {
                                    name: "项目(个)",
                                    numbers: "373"
                                },
                                {
                                    name: "检查天数(天)",
                                    numbers: "100"
                                },
                            ],
                        },
                    ],
                },
                {
                    children: [{
                            title: "保利物业百优行动品检项目",
                            details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设，以及场景化管理方面建立更出色的管理能力…",
                            path: "/customerCase/caseDetails/1",
                            children: [{
                                    name: "分公司(家)",
                                    numbers: "26"
                                },
                                {
                                    name: "项目(个)",
                                    numbers: "373"
                                },
                                {
                                    name: "检查天数(天)",
                                    numbers: "100"
                                },
                            ],
                        },
                        {
                            title: "西塘全域化公共服务项目",
                            details: "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导、企业协同、服务对象积极参与”的三位一体全域化服务，打…",
                            path: "/customerCase/caseDetails/2",
                            children: [{
                                    name: "分公司(家)",
                                    numbers: "453"
                                },
                                {
                                    name: "项目(个)",
                                    numbers: "10"
                                },
                                {
                                    name: "检查天数(天)",
                                    numbers: "2w+"
                                },
                            ],
                        },
                    ],
                },
            ], //相关案例
            listDownloadList: [{
                    img: require("../../assets/product/Android@3x.png"),
                    icon: require("../../assets/product/40@3x.png"),
                    title: "安卓手机端",
                    details: "安卓用户，手机扫一扫下载客户端",
                },
                {
                    img: require("../../assets/product/iOS@3x.png"),
                    icon: require("../../assets/product/ios  40@3x.png"),
                    title: "iOS小程序",
                    details: "iOS用户可微信扫码直接访问小程序",
                },
            ], //产品下载
						listDownloadList_recruit: [{
						        img: require("../../assets/product/Android@3x.png"),
						        icon: require("../../assets/product/40@3x.png"),
						        title: "安卓手机端",
						        details: "安卓用户，手机扫一扫下载客户端",
						    },
						    {
						        img: require("../../assets/product/recruit.png"),
						        icon: require("../../assets/product/ios  40@3x.png"),
						        title: "iOS小程序",
						        details: "iOS用户可微信扫码直接访问小程序",
						    },
						], //产品下载
            listProblemList: [{
                    isOpen: false,
                    img: require("../../assets/product/01@3x.png"),
                    title: "App用户绑定手机号不成功如何处理？",
                    details: "用户绑定失败说明该用户的手机号不在我们的数据库中，可以联系项目负责人，让其在管理后台新增人员信息后再重新登录",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/02@3x.png"),
                    title: "用户登录App显示“该手机号已绑定设备”如何处理？",
                    details: "用户登录App显示“该手机号已绑定设备”说明该手机号已在其他设备/手机上登录绑定过，可以联系项目负责人，让其在管理后台解绑后再重新登录",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/03@3x.png"),
                    title: "用户进入App首页显示白屏如何处理？",
                    details: "用户进入App首页显示白屏可能是因为用户没联网导致的，可以检查一下网络设置",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/04@3x.png"),
                    title: "用户提交报事单不成功如何处理？",
                    details: "1、请检查报事单内照片、位点、项目等是否均填写完毕；2、检查管理对象类别是否选择——（若涉及商户请选择管控类并选择或添加对应商户）；3、检查手机是否开启允许获取定位（安卓用户可以打开手机设置，找到位置信息，选择对应App打开开关即可；ios用户可以打开手机设置，找到对应App，打开位置开关即可）",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/05@3x.png"),
                    title: "用户授权手机号仍进入小程序欢迎使用页面该如何处理？",
                    details: "用户授权手机号仍进入小程序欢迎使用页面说明该用户的手机号不在我们的数据库中，可以联系项目负责人，让其在管理后台新增后再重新登录",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/06.png"),
                    title: "在填写报事单信息时位点/事项没有具体选项该如何处理？",
                    details: "在填写报事单信息时位点/事项没有具体选项说明该用户没有配置相应权限，可以联系项目负责人，让其在管理后台给该用户配置位点和事项权限后再重新填写报事单信息",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/07.png"),
                    title: "App更新速度慢是什么原因？",
                    details: "App更新速度慢是因为当前更新人数过多，可选择其他时间更新",
                },
                {
                    isOpen: false,
                    img: require("../../assets/product/08.png"),
                    title: "报事单能否延后创建，工作时没有时间处理？",
                    details: "报事单是可以延后创建的，我们允许用户在现场拍照留存后先处理事情，等空闲时或者离岗后再从相册选取照片创建报事单",
                },
            ], //常见问题-作业平台
						listProblemList_recuit: [{
						        isOpen: false,
						        img: require("../../assets/product/01@3x.png"),
						        title: "为什么投递简历显示用户没有填写，又不让我用户填写简历？",
						        details: "不能填写简历是因为用户没有授权手机号登录，我们不允许填写简历，从而不能投递简历",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/02@3x.png"),
						        title: "为什么用户填写了简历但还是投递不成功？",
						        details: "可能是因为该岗位已招满，从而导致投递不成功",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/03@3x.png"),
						        title: "为什么用户分享了岗位信息好友点击后没有加分？",
						        details: "用户分享岗位信息好友点击后没有加分可能和这几个原因有关：1.好友不是第一次登录我们的小程序，意思就是在用户分享链接给好友前好友就已经登录过我们的小程序；2.好友是第一次登录我们的小程序，但没有授权手机号，没有授权手机号我们是无法获取他的信息的，从而没法加分",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/04@3x.png"),
						        title: "为什么用户面试成功10人，面试第11人点成功没反应？",
						        details: "因为该岗位当前只招收10人，如需扩招请联系项目负责人更改岗位招收人数",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/05@3x.png"),
						        title: "为什么用户看不到他的积分和影响力？",
						        details: "因为该用户目前是外部人员身份，只有内部人员有积分和影响力，如需调整请联系项目负责人将该用户加到小程序管理后台",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/06.png"),
						        title: "用户怎么知道积分兑换是否成功？",
						        details: "小程序个人中心-服务通知管理-允许通知，点击后可收到积分兑换成功通知",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/07.png"),
						        title: "用户想了解一下积分激励机制，该怎么做？",
						        details: "小程序个人中心-我的积分-右上角积分明细-右上角积分说明，按照这个步骤就可以了解积分激励机制了",
						    },
						], //常见问题--内推
						listProblemList_teamwork: [{
						        isOpen: false,
						        img: require("../../assets/product/01@3x.png"),
						        title: "用户App绑定不成功如何处理？",
						        details: "用户App绑定不成功说明该用户的手机号不在我们的数据库中，可以联系项目负责人，让其在管理后台新增后再重新登录",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/02@3x.png"),
						        title: "用户登录App显示“该手机号已绑定设备”如何处理？",
						        details: "用户登录App显示“该手机号已绑定设备”说明该手机号已在其他设备/手机上登录绑定过，可以联系项目负责人，让其在管理后台解绑后再重新登录",
						    },
						    {
						        isOpen: false,
						        img: require("../../assets/product/03@3x.png"),
						        title: "用户进入App首页显示白屏如何处理？",
						        details: "用户进入App首页显示白屏可能是因为用户没联网导致的，可以检查一下网络设置",
						    },
						], //常见问题--协同平台
            // 
            // 解决方案更多解决方案文案
            list5: [{
                    title: "智慧园区解决方案",
                    details: "大数据时代，数据的价值亟需被挖掘利用起来，真正助力企业的发展。数据可视化与自助分析，以极具视觉冲击力的方式...",
                    path: '/solution'
                },
                {
                    title: "数字可视化解决方案",
                    details: "大数据时代，数据的价值亟需被挖掘利用起来，真正助力企业的发展。数据可视化与自助分析，以极具视觉冲击力的方式...",
                    path: '/solution2'
                },
                {
                    title: "环卫一体化解决方案",
                    details: "综合利用物联网、云计算等技术，对环卫管理所涉及到的人、车、物、事进行全过程实时管理，提升环卫作业质量，降低环卫运...",
                    path: '/solution3'
                },
                {
                    title: "智慧景区解决方案",
                    details: "通过智慧物联、集中管控、统一运营的理念，搭配起景区生态链，全流程和全场景的景区数字化思考。",
                    path: '/solution4'
                },
            ], //更多解决方案
        };
    },
    mounted() {
        // 调用 解决方案区分 显示
        this.list5.splice(this.id, 1);
    },
    methods: {
			showQrCode(){
				this.$refs.contactUs.showQrCode = true;
				this.startScroll();
			},
			//二维码是否显示
			qrCode() {
					// this.isqrCode = !this.isqrCode;
					this.$refs.contactUs.isqrCode = !this.$refs.contactUs.isqrCode;
					// console.log(this.$refs.contactUs.isqrCode, "this.$refs");
					if (this.screenWidth <= 750) {
							this.copy("sparklehorse_rip");
					}
					this.startScroll();
			},
			startScroll() {
					this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
					document.body.style.cssText += "position:fixed;width:100%;top:-" + this.scrollTop + "px;";
			},
			// 复制功能
			copy(data) {
					// 存储传递过来的数据
					let OrderNumber = data;
					// 创建一个input 元素
					// createElement() 方法通过指定名称创建一个元素
					let newInput = document.createElement("input");
					// 讲存储的数据赋值给input的value值
					newInput.value = OrderNumber;
					// appendChild() 方法向节点添加最后一个子节点。
					document.body.appendChild(newInput);
					// 选中input元素中的文本
					// select() 方法用于选择该元素中的文本。
					newInput.select();
					// 执行浏览器复制命令
					//  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
					document.execCommand("Copy");
					// 清空输入框
					newInput.remove();
			},
			// 解决方案  更多解决方案跳转
			toSolutionPage(path) {
					this.$router.push(path);
			},

			// 产品下载 移动端下载
			downLoad(e) {
					if (e == 0) {
							window.location.href = "http://www.pingzhengyun.com/portal/__PZY__7F0F25B_1127183134.apk";
					}
			},
    },
};