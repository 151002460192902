<!-- Header -->
<template>
    <div>
        <div class="nav-PC" :class="navScroll ? 'bg-color' : 'nav-PC'">
            <img src="../../assets/navigator/logoPzy.png" v-if="!navScroll" alt="" @click="navImgClick" />
            <img src="../../assets/navigator/logo2@3x.png" v-else alt="" @click="navImgClick" />
            <ul class="menu-ul">
                <li v-for="(item, index) in navList" :key="index">
                    <div class="menu-ul-list" @click="navClick(index)" :class="[item.isActive ? 'isActive' : '']">
                        {{ item.name }}
                    </div>

                    <div class="item-children" v-if="item.children" :class="navScroll ? 'bg-color' : 'item-children'">
                        <ul class="item-children-ul">
                            <li
                                v-for="(item1, index1) in item.children"
                                :key="index1"
                                @click="navLiClick(item1, index)"
                            >
                                <!-- {{ item1.name }} -->
                                <router-link class="item-children-ul-li" :to="item1.path">{{ item1.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="nav-mobile" :class="navScroll ? 'bg-color' : 'nav-mobile'">
            <img src="../../assets/navigator/logoPzy.png" v-if="!navScroll" alt="" @click="navImgClick" />
            <img src="../../assets/navigator/logo2@3x.png" v-else alt="" @click="navImgClick" />
            <img src="../../assets/mobile/menu.png" v-if="!navScroll" @click="openTab()" />
            <img src="../../assets/navigator/b@3x.png" v-else @click="openTab()" />
        </div>
        <div class="mobile-navList" v-if="isMobile">
            <div class="close-tab" @click="closeTab()">
                <img src="../../assets/navigator/close.png" alt="" />
            </div>
            <div class="tab-list">
                <ul>
                    <li v-for="(item, index) in navList" :key="index">
                        <div class="tab-list-li">
                            <span @click="navClick(index)" :class="item.isActive ? 'bold' : ''">{{ item.name }}</span>
                            <div class="img" @click="openList(index)">
                                <img
                                    v-if="item.hasChildren"
                                    src="../../assets/navigator/open@3x.png"
                                    alt=""
                                    :class="index == isOpen ? 'rotate' : ''"
                                />
                            </div>
                        </div>
                        <template v-if="index == isOpen">
                            <div
                                class="tab-list-li-children"
                                v-for="(item1, index1) in item.children"
                                :key="index1"
                                @click="navLiClick(item1, index)"
                            >
                                <router-link class="router-link" :to="item1.path">{{ item1.name }}</router-link>
                            </div>
                        </template>
                    </li>
                </ul>
                <p><img src="../../assets/navigator/tel@3x.png" alt="" /><span>139 1654 0399</span></p>
                <p><img src="../../assets/navigator/email@3x.png" alt="" /><span>liujunyang@pingzhengyun.com</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import db from "../../utils/localstorage";
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            // navList: [
            //     { name: "首页", isActive: true, path: "/" },
            //     {
            //         name: "产品",
            //         isActive: false,
            //         path: "/product",
            //         hasChildren: true,
            //         children: [
            //             { name: "智慧作业平台", path: "/product" },
            //             { name: "协同治理平台", path: "/product2" },
            //             { name: "数字化内推平台", path: "/product3" },
            //         ],
            //     },
            //     {
            //         name: "解决方案",
            //         isActive: false,
            //         path: "/solution",
            //         hasChildren: true,
            //         children: [
            //             { name: "智慧园区解决方案", path: "/solution" },
            //             { name: "可视化解决方案", path: "/solution2" },
            //             { name: "环卫一体化解决方案", path: "/solution3" },
            //             { name: "智慧景区解决方案", path: "/solution4" },
            //         ],
            //     },
            //     {
            //         name: "客户案例",
            //         isActive: false,
            //         path: "/customerCase",
            //     },
            //     { name: "公服智库/财大", isActive: false },
            //     {
            //         name: "加入我们",
            //         isActive: false,
            //         path: "/joinUs",
            //     },
            // ], //nav内容
            isMobile: false,
            // navList: [],
            screenWidth: Number,
            navScroll: false, //滚动距离改变底色
            marginTop: 1,
            isOpen: -1,
            scrollTop: Number,
        };
    },
    directives: {
        fixed: {
            // inserted 被绑定元素插入父节点时调用
            inserted() {
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                document.body.style.cssText += "position:fixed;width:100%;top:-" + scrollTop + "px;";
            },
            // unbind 指令与元素解绑时调用
            unbind() {
                let body = document.body;
                body.style.position = "";
                let top = body.style.top;
                document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
                body.style.top = "";
            },
        },
    },
    computed: {
        // ...mapState(["navList"]),
        navList: {
            get() {
                return this.$store.state.navList;
            },
            set() {
                return this.$store.state.navList;
            },
        },
    },
    watch: {},
    created() {
        // this.navList = this.$store.state.navList;
        // console.log(this.$route);
        if (Object.keys(db.get("navList")).length > 0) {
            this.navList = db.get("navList");
        }
        this.screenWidth = window.document.body.clientWidth || document.documentElement.clientWidth;
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    // 组件销毁前
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        db.remove("navList");
    },
    watch: {
        $route(to, from) {
            if (to) {
                scrollTo(0, 0);
                this.isOpen = -1;
                if (to.fullPath == "/download") {
                    this.navList.map((item, index) => {
                        item.isActive = false;
                    });
                    this.marginTop = 360;
                } else {
                    this.marginTop = 800;
                }
                let pathArr = ["/contactUs", "/aboutUs", "/joinUs"];
                if (pathArr.includes(to.fullPath)) {
                    this.navList.map((item, index) => {
                        item.isActive = item.path == "/joinUs" ? true : false;
                    });
                }
            }
        },
    },
    methods: {
        // 导航栏点击选中事件
        navClick(index) {
            this.navList.map((a, b) => {
                a.isActive = b === index ? true : false;
            });
            this.$router.push(this.navList[index].path);
            db.save("navList", this.navList);
            this.$store.commit("setNavList", this.navList);

            this.isMobile = false;
            this.endScroll();
        },
        navLiClick(i, e) {
            this.navList.map((a, b) => {
                a.isActive = b === e ? true : false;
                this.isMobile = false;
            });
            this.endScroll();
            this.$store.state.navList = this.navList;
            scrollTo(0, 0);
        },
        // 监听屏幕变化改变显示
        handleScroll() {
            // 页面滑动的距离
            let scrollTop = document.documentElement.scrollTop;
            // 当页面滑动的距离大于?时元素显示，否则不改变
            // this.marginTop
            if (scrollTop >= 10) {
                this.navScroll = true;
            } else {
                this.navScroll = false;
            }
        },
        // tab 子列表是否展开
        openList(index) {
            this.isOpen = index == this.isOpen ? -1 : index;
        },
        // 弹窗开启禁止滚动
        startScroll() {
            this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            document.body.style.cssText += "position:fixed;width:100%;top:-" + this.scrollTop + "px;";
        },
        // 弹窗关闭恢复滚动
        endScroll() {
            let body = document.body;
            body.style.position = "";
            let top = body.style.top;
            document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
            body.style.top = "";
        },
        // 移动端弹窗开启
        openTab() {
            this.isMobile = !this.isMobile;
            this.startScroll();
        },
        // 移动端弹窗关闭
        closeTab() {
            this.isMobile = !this.isMobile;
            this.endScroll();
        },

        // logo图跳转首页
        navImgClick() {
            this.$router.push({ path: "/" });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    .nav-PC {
        min-width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13rem;
        box-sizing: border-box;
        position: fixed;
        z-index: 999;
        background: rgba(#ffffff, 0.04);
        img {
            width: 8.75rem;
            height: 2.7rem;
            &:nth-last-child(1) {
                display: none;
            }
        }
        .menu-ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            list-style-type: none;
            height: inherit;
            li {
                position: relative;
                .menu-ul-list {
                    padding: 0 2.175rem;
                    box-sizing: border-box;
                    color: rgba(#eff6ff, 0.5);
                    height: inherit;
                    line-height: 4.4rem;
                    border-bottom: 0.2rem;
                    border-bottom-style: solid;
                    border-bottom-color: transparent;
                    font-size: 0.9rem;
                    border-right: 0.05rem solid rgba(#ffffff, 0.06);
                    &:hover {
                        color: #eff6ff;
                        background: rgba(#2897db, 0.2);
                        cursor: pointer;
                    }
                }
                &:hover {
                    .item-children {
                        display: block;
                    }
                }
                .item-children {
                    display: none;
                    position: absolute;
                    top: 100%;
                    background-color: rgba(#fff, 0.2);
                    border-radius: 4px;
                    .item-children-ul {
                        li {
                            font-size: 0.85rem;
                            color: #fff;
                            padding: 0.5rem 1.5rem 0.5rem 1rem;
                            box-sizing: border-box;
                            cursor: pointer;
                            word-break: keep-all;

                            .item-children-ul-li {
                                height: 100%;
                                width: 100%;
                                display: inline-block;
                                color: #fff;
                                text-decoration: none;
                                &:hover {
                                    color: #1976e1;
                                }
                            }
                        }
                    }
                }
                .bg-color {
                    background: #081a24;
                    color: #333;
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
                }

                .isActive {
                    color: #2897db;
                    background: rgba(#2897db, 0.2);
                    border-bottom: 0.2rem;
                    border-bottom-style: solid;
                    border-bottom-color: #2897db;
                }
            }
        }
    }
    .bg-color {
        background: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
        .menu-ul {
            li {
                .menu-ul-list {
                    color: #031722;
                    &:hover {
                        color: #1976e1;
                        cursor: pointer;
                    }
                    .isActive {
                        color: #1976e1;
                        background: #f5f5fb;
                        border-bottom: 0.2rem;
                        border-bottom-style: solid;
                        border-bottom-color: #2897db;
                    }
                }
            }
        }
    }
    .nav-mobile {
        display: none;
    }
}
@media screen and (max-width: 750px) {
    //  导航栏样式开始
    .nav-PC {
        display: none;
    }
    .nav-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.3rem 2.5rem;
        box-sizing: border-box;
        position: fixed;
        z-index: 999;
        top: 0;
        width: 100vw;
        img {
            &:nth-child(1) {
                width: 10.6765rem;
                height: 3.3rem;
            }
            &:nth-last-child(1) {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
    .bg-color {
        background: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
    }
    // 导航栏样式结束
    .mobile-navList {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-width: 100vw;
        height: 100%;
        overflow: hidden;
        padding: 1.8rem 2.5rem 0;
        box-sizing: border-box;
        background: rgba(#031722, 0.95);
        .close-tab {
            text-align: right;
            img {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
        .tab-list {
            margin: 5.775rem 0;
            min-height: 47.5rem;
            // overflow-y: scroll;
            ul {
                list-style: none;
                li {
                    border-bottom: 1px solid rgba(#ececf2, 0.2);
                    &:nth-child(1) {
                        border-top: 1px solid rgba(#ececf2, 0.2);
                    }
                    .tab-list-li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 2.175rem 0;
                        box-sizing: border-box;
                        cursor: pointer;

                        span {
                            font-size: 1.6rem;
                            line-height: 1.6rem;
                            color: #ffffff;
                            display: block;
                            width: 25rem;
                        }
                        .bold {
                            font-weight: bold;
                            color: #1976e1;
                        }
                        .img {
                            padding-left: 2.5rem;
                            height: 100%;
                            img {
                                transition: all 0.4s ease;
                                width: 1.2rem;
                                height: 1.2rem;
                                box-sizing: border-box;
                            }
                            .rotate {
                                transform: rotate(-45deg);
                            }
                        }
                    }
                    .tab-list-li-children {
                        padding-bottom: 1rem;
                        box-sizing: border-box;
                        font-size: 1.4rem;
                        &:hover {
                            color: #1976e1;
                        }
                    }
                }
            }
            p {
                display: flex;
                align-items: center;
                margin-top: 2rem;
                flex-wrap: wrap;
                img {
                    width: 3.1rem;
                    height: 3.1rem;
                    margin-right: 1.5rem;
                }
                span {
                    flex: 1;
                    font-size: 1.6rem;
                    color: #ffffff;
                }
                &:nth-last-child(2) {
                    margin-top: 3.025rem;
                }
            }
        }
    }
}

.router-link {
    height: 100%;
    width: 100%;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: #1976e1;
    }
}
</style>
