<!--  -->
<template>
    <keep-alive>
        <PageLayout>
            <share></share>
        </PageLayout>
    </keep-alive>
</template>

<script>
import PageLayout from "@/views/layout/PageLayout";
import share from "@/components/share/share";
export default {
    name: "pageView",
    components: { PageLayout, share },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss"></style>
